import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import React, { useEffect, useState } from "react";
import FormSuccess from "./FormSuccess";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TimePicker from "components/TimePicker/TimePicker";

// Import API Data
import useGetGroupType from "./data/useGetGroupType";
import useCreateOrder from "./data/useCreateOrder";

// images
import guestFormCoverPhoto from "images/guest-form-cover.png";
import DatePicker from "components/DatePicker/DatePicker";
import { formatDate } from "commons/utils/helpers";

const BookNowForm = () => {
  const [propertyCategory, setPropertyCategory] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [duration, setDuration] = useState("");
  const [noOfGuests, setNoOfGuests] = useState("");
  const [groupTypeValue, setGroupTypeValue] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [profession, setProfession] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [booking, setBooking] = useState("");
  const [alcoholOrHookah, setAlcoholOrHookah] = useState("");
  const [customPropertyName, setCustomPropertyName] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const propertySlugFromUrl = searchParams.get("p_slug");

  // validating fields
  const [propertyNameError, setPropertyNameError] = useState("");
  const [checkInDateError, setCheckInDateError] = useState("");
  const [checkInTimeError, setCheckInTimeError] = useState("");
  const [durationError, setDurationError] = useState("");
  const [noOfGuestsError, setNoOfGuestsError] = useState("");
  const [groupTypeValueError, setGroupTypeValueError] = useState("");
  const [alcoholOrHookahError, setAlcoholOrHookahError] = useState("");
  const [nameError, setNameError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [professionError, setProfessionError] = useState("");
  const [whatsappNoError, setWhatsappNoError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [bookingError, setBookingError] = useState("");
  const [formIsValid, setFormIsValid] = useState(undefined);

  const todayDate = dayjs(
    new Date().toISOString().split("T")[0] + "T00:00:00.000"
  );

  const [data, isGroupTypeError, isGroupTypeLoading, getGroupType] =
    useGetGroupType();
  const [
    createOrderData,
    isCreateOrderError,
    isCreateOrderLoading,
    postCreateOrder,
  ] = useCreateOrder();

  const todayTimeDate = new Date();
  const todayTime = dayjs()
    .set("hour", todayTimeDate.getHours())
    .startOf("hour");
  useEffect(() => {
    getGroupType();
  }, []);

  useEffect(() => {
    if (createOrderData) {
      setFormSubmitted(true);
    }
  }, [createOrderData]);

  useEffect((e) => {
    if (propertySlugFromUrl) {
      setPropertyName(propertySlugFromUrl);
    } else {
      setPropertyName("");
    }
  }, []);

  const handleDateChange = (newValue) => {
    if (newValue.isBefore(todayDate, "day")) {
      setCheckInDateError("Selected date cannot be earlier than today");
    } else {
      setCheckInDateError("");
      setCheckInDate(newValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validating each field before submitting
    const isFormValidArray = [
      validateField(propertyName, setPropertyNameError, "Space Name"),
      validateField(checkInDate, setCheckInDateError, "Check-In Date"),
      validateField(checkInTime, setCheckInTimeError, "Check-In Time"),
      validateField(duration, setDurationError, "Duration"),
      validateField(
        noOfGuests,
        setNoOfGuestsError,
        "Number of Guests",
        "number"
      ),
      validateField(groupTypeValue, setGroupTypeValueError, "Group Type"),
      validateField(
        alcoholOrHookah,
        setAlcoholOrHookahError,
        "Alcohol or Hookah"
      ),
      validateField(name, setNameError, "Your Full Name"),
      validateField(age, setAgeError, "Your Age", "number"),
      validateField(profession, setProfessionError, "Your Profession"),
      validateField(
        whatsappNo,
        setWhatsappNoError,
        "Your Whatsapp Number",
        "number"
      ),
      validateField(
        contactNo,
        setContactNoError,
        "Additional Contact Number",
        "number"
      ),
      validateField(booking, setBookingError, "Purpose of Booking"),
    ];

    const isFormValidSet = new Set(isFormValidArray);
    var isFormValid = false;
    if (isFormValidSet.has(true)) {
      isFormValid = true;
    }
    if (isFormValidSet.has(false)) {
      isFormValid = false;
    }
    if (isFormValid) {
      let formattedCheckInDate = formatDate(new Date(checkInDate));

      let has_alcohol = false;
      let has_hookah = false;
      if (alcoholOrHookah === "Both") {
        has_alcohol = true;
        has_hookah = true;
      } else if (alcoholOrHookah === "None") {
        has_alcohol = false;
        has_hookah = false;
      } else if (alcoholOrHookah === "Alcohol") {
        has_alcohol = true;
        has_hookah = false;
      } else if (alcoholOrHookah === "Hookah") {
        has_alcohol = false;
        has_hookah = true;
      }

      let formData = {
        checkin_datetime: formattedCheckInDate + " " + checkInTime,
        stay_duration: duration,
        number_of_guests: noOfGuests,
        group_type: groupTypeValue,
        has_alcohol: has_alcohol,
        has_hookah: has_hookah,
        guest_name: name,
        guest_age: age,
        guest_profession: profession,
        guest_phone: whatsappNo,
        guest_alternate_phone: contactNo,
      };
      if (propertyCategory === "notSure") {
        formData["custom_property_name"] = propertyName;
      } else {
        formData["space"] = propertyName;
      }
      if (booking === "otherBookingPurpose") {
        formData["booking_purpose"] = customPropertyName;
      } else {
        formData["booking_purpose"] = booking;
      }
      postCreateOrder(formData);
    }
  };

  const validateField = (value, setError, fieldName, inputType = "text") => {
    var isFieldValid = true;
    var errorText = "";
    if (typeof value === "string" && value.trim() === "") {
      isFieldValid = false;
      errorText = `Please enter ${fieldName}`;
    } else if (value === null || value === undefined) {
      isFieldValid = false;
      errorText = `Please enter ${fieldName}`;
    }

    if (inputType === "number") {
      if (isNaN(value) || isNaN(parseFloat(value))) {
        isFieldValid = false;
        errorText = "Please enter numeric values.";
      }
    }

    setError(errorText);
    setFormIsValid(isFieldValid);
    return isFieldValid;
  };

  const handleDurationRadioChange = (e) => {
    setDuration(e.target.value);
  };

  const handleBookingRadioChange = (e) => {
    setBooking(e.target.value);
  };

  const handleAlocoholOrHookahRadioChange = (e) => {
    setAlcoholOrHookah(e.target.value);
  };

  const handlePropertyCategory = (e) => {
    setPropertyCategory(e.target.value);
    setPropertyName("");
  };

  const renderRadio = (name, id, label, onChange) => {
    return (
      <div className="flex items-center">
        <input
          value={id}
          id={id + name}
          name={name}
          type="radio"
          onChange={onChange}
          className="ring-opacity-0 h-6 w-6 m-1 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-base font-normal text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const formContent = () => (
    <>
      {isGroupTypeLoading || isCreateOrderLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        data && (
          <div className="px-4 max-w-3xl mx-auto pb-24 pt-6 lg:pb-32 ">
            <h2 className="text-3xl font-semibold mb-2">
              CoziQ Private Dining
            </h2>
            <div>
              <img
                className="w-full mb-6"
                src={guestFormCoverPhoto}
                alt="book_now_cover_image"
              />
            </div>

            <div className="space-y-8 listingSection__wrap">
              <FormItem label="Do you know which space you want to book?">
                <Select
                  id="propertyName"
                  value={propertyCategory}
                  onChange={(e) => handlePropertyCategory(e)}
                >
                  <option value={""} disabled>
                    Select an option
                  </option>
                  <option value="changeProperty">Yes, I know the name</option>
                  <option value="notSure">No, I need suggestions</option>
                </Select>

                {propertyCategory === "changeProperty" ? (
                  <Select
                    id="allProperties"
                    value={propertyName}
                    onChange={(e) => setPropertyName(e.target.value)}
                    className="mt-4"
                  >
                    <option value={""} disabled>
                      Select the Space Name
                    </option>
                    {data &&
                      data.properties.map((property) => (
                        <option key={property.id} value={property.slug}>
                          {property.name}
                        </option>
                      ))}
                  </Select>
                ) : null}

                {propertyCategory === "notSure" ? (
                  <FormItem desc="Don't know exact space name? Share the locality or Instagram link, if available. Describe the space you want to book. We'll find the perfect option for you!">
                    <Input
                      type="text"
                      placeholder="Enter requirements/ locality/ intagram link"
                      value={propertyName}
                      onChange={(e) => setPropertyName(e.target.value)}
                      className="mt-4"
                    />
                  </FormItem>
                ) : null}

                {!propertyName && (
                  <p className="text-red-500 text-sm mt-2">
                    {propertyNameError}
                  </p>
                )}
              </FormItem>
              <FormItem label="Check-In-Date(Month/Day)">
                <DatePicker
                  minDate={todayDate}
                  value={checkInDate}
                  format="MM-DD-YYYY"
                  onChange={handleDateChange}
                  style={{ textTransform: "uppercase", textAlign: "left" }}
                  required
                />
                {!checkInDate && (
                  <p className="text-red-500 text-sm mt-2">
                    {checkInDateError}
                  </p>
                )}
              </FormItem>

              <FormItem label="Check-In-Time">
                <TimePicker
                  value={checkInTime}
                  onChange={(newValue) => {
                    const timeString = newValue.$d.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    });
                    setCheckInTime(timeString);
                  }}
                  required
                />

                {!checkInTime && (
                  <p className="text-red-500 text-sm mt-2">
                    {checkInTimeError}
                  </p>
                )}
              </FormItem>
              <FormItem label="Duration">
                <div>
                  {renderRadio(
                    "Duration",
                    "1",
                    "1 Hour",
                    handleDurationRadioChange
                  )}
                  {renderRadio(
                    "Duration",
                    "2",
                    "2 Hours",
                    handleDurationRadioChange
                  )}
                  {renderRadio(
                    "Duration",
                    "3",
                    "3 Hours",
                    handleDurationRadioChange
                  )}
                </div>
                {!duration && (
                  <p className="text-red-500 text-sm mt-2">{durationError}</p>
                )}
              </FormItem>
              <FormItem
                label="Number of  Guests"
                desc="Maximum 50 guests allowed"
              >
                <Input
                  type="number"
                  placeholder="1 Person = 1 guest"
                  value={noOfGuests}
                  onChange={(e) => setNoOfGuests(e.target.value)}
                  max={50}
                />
                {!noOfGuests && (
                  <p className="text-red-500 text-sm mt-2">{noOfGuestsError}</p>
                )}
                {noOfGuests > 50 && (
                  <p className="text-red-500 text-sm mt-2">
                    Number of guests cannot exceed 50
                  </p>
                )}
                {noOfGuests !== "" && noOfGuests < 1 && (
                  <p className="text-red-500 text-sm mt-2">
                    Number of guests cannot be less than 1
                  </p>
                )}
              </FormItem>
              <FormItem label="Group type" placeholder="Group Type">
                {!isGroupTypeLoading && data ? (
                  <Select
                    id="groupType"
                    value={groupTypeValue}
                    onChange={(e) => setGroupTypeValue(e.target.value)}
                  >
                    <option value={""} disabled>
                      Select
                    </option>
                    {data.group_types &&
                      data.group_types.map((group_type) => (
                        <option key={group_type.id} value={group_type.id}>
                          {group_type.name}
                        </option>
                      ))}
                  </Select>
                ) : null}
                {!groupTypeValue && (
                  <p className="text-red-500 text-sm mt-2">
                    {groupTypeValueError}
                  </p>
                )}
              </FormItem>
              <FormItem label="Would you be bringing Alcohol or Hookah?">
                <div>
                  {renderRadio(
                    "Alcohol or Hookah",
                    "Alcohol",
                    "Alcohol",
                    handleAlocoholOrHookahRadioChange
                  )}
                  {renderRadio(
                    "Alcohol or Hookah",
                    "Hookah",
                    "Hookah",
                    handleAlocoholOrHookahRadioChange
                  )}
                  {renderRadio(
                    "Alcohol or Hookah",
                    "Both",
                    "Both",
                    handleAlocoholOrHookahRadioChange
                  )}
                  {renderRadio(
                    "Alcohol or Hookah",
                    "None",
                    "None",
                    handleAlocoholOrHookahRadioChange
                  )}
                </div>
                {!alcoholOrHookah && (
                  <p className="text-red-500 text-sm mt-2">
                    {alcoholOrHookahError}
                  </p>
                )}
              </FormItem>
              <FormItem label="Your Full Name">
                <Input
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {!name && (
                  <p className="text-red-500 text-sm mt-2">{nameError}</p>
                )}
              </FormItem>
              <FormItem label="Your Age">
                <Input
                  type="number"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
                {!age && (
                  <p className="text-red-500 text-sm mt-2">{ageError}</p>
                )}

                {age !== "" && age < 1 && (
                  <p className="text-red-500 text-sm mt-2">
                    Age cannot be less than 1
                  </p>
                )}
              </FormItem>
              <FormItem label="Your Profession">
                <Input
                  type="text"
                  placeholder="Profession"
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                />
                {!profession && (
                  <p className="text-red-500 text-sm mt-2">{professionError}</p>
                )}
              </FormItem>
              <FormItem label="Your Whatsapp Number">
                <Input
                  type="number"
                  placeholder="Whatsapp Number"
                  value={whatsappNo}
                  onChange={(e) => setWhatsappNo(e.target.value)}
                />
                {!whatsappNo && (
                  <p className="text-red-500 text-sm mt-2">{whatsappNoError}</p>
                )}

                {whatsappNo && whatsappNo.length !== 10 && (
                  <p className="text-red-500 text-sm mt-2">
                    Whatsapp Number should have exactly 10 digits
                  </p>
                )}
              </FormItem>
              <FormItem label="Additional Contact Number">
                <Input
                  type="number"
                  placeholder="Additional Contact Number"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
                {!contactNo && (
                  <p className="text-red-500 text-sm mt-2">{contactNoError}</p>
                )}
                {contactNo && contactNo.length !== 10 && (
                  <p className="text-red-500 text-sm mt-2">
                    Contact Number should have exactly 10 digits
                  </p>
                )}
              </FormItem>
              <FormItem label="Purpose of Booking">
                <div>
                  {renderRadio(
                    "Purpose of Booking",
                    "Casual Dining",
                    "Casual Dining",
                    handleBookingRadioChange
                  )}
                  {renderRadio(
                    "Purpose of Booking",
                    "Movie Screening",
                    "Movie Screening/ Theater",
                    handleBookingRadioChange
                  )}
                  {renderRadio(
                    "Purpose of Booking",
                    "Candle Light",
                    "Candle Light",
                    handleBookingRadioChange
                  )}
                  {renderRadio(
                    "Purpose of Booking",
                    "Special Occassion",
                    "Special Occassion(Birthday/Anniversary)",
                    handleBookingRadioChange
                  )}
                  {renderRadio(
                    "Purpose of Booking",
                    "Late Night Celebration",
                    "Late Night Celebration",
                    handleBookingRadioChange
                  )}
                  {renderRadio(
                    "Purpose of Booking",
                    "Remote Work",
                    "Remote Work",
                    handleBookingRadioChange
                  )}
                  {renderRadio(
                    "Purpose of Booking",
                    "otherBookingPurpose",
                    "Other",
                    handleBookingRadioChange
                  )}
                </div>
                {booking === "otherBookingPurpose" ? (
                  <Input
                    type="text"
                    placeholder="Other Purpose of Booking"
                    value={customPropertyName}
                    onChange={(e) => setCustomPropertyName(e.target.value)}
                    className="mt-2"
                  />
                ) : null}

                {!booking && (
                  <p className="text-red-500 text-sm mt-2">{bookingError}</p>
                )}
              </FormItem>
              {formIsValid === false && (
                <p className="text-red-500 text-sm mt-2">
                  <strong>Form Invalid:</strong> Please check the values you
                  entered!
                </p>
              )}
              <div className="flex justify-start mt-2">
                <ButtonPrimary type="submit" onClick={handleSubmit}>
                  Submit
                </ButtonPrimary>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );

  return <>{!formSubmitted ? formContent() : <FormSuccess />}</>;
};
export default BookNowForm;
