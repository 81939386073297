import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";


const GuestsInput = ({
  className = "",
  handleSearchDataChange,
  searchKeyName,
  defaultValue,
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue?.guestAdults || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue?.guestAdults || 0);
  }, [defaultValue?.guestAdults]);


  const handleChangeData = (value, type) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
  };


  const setDefaultValues = () => {
    setGuestAdultsInputValue(defaultValue);
  }

  useEffect(() => {
    handleSearchDataChange({[searchKeyName]: guestAdultsInputValue});
  }, [guestAdultsInputValue])

  useEffect(() => {
    if (defaultValue){
      setDefaultValues();
    }
  }, [defaultValue])


  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, "guestAdults")}
        max={50}
        label="Adults"
        desc=""
      />
    </div>
  );
};

export default GuestsInput;
