import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingPropertyPage from "containers/ListingPropertyPage/ListingPropertyPage.jsx";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PropertyDetailPage from "containers/PropertyDetailPage/PropertyDetailPage.jsx";
import {
  BLOG_LISTING_PAGE_URL,
  BLOG_PAGE_URL,
  CHECKOUT_PAGE_URL,
  GET_PROPERTY_DETAIL_URL,
  PAYMENT_STATUS_PAGE_URL,
} from "routers/constants";
import { BOOK_NOW_PAGE_URL } from "routers/constants";
import useGetPropertyFiltersData from "containers/ListingPropertyPage/data/useGetPropertyFiltersData";
import BookNowForm from "containers/BookNowForm/BookNowForm";
import BlogListingPage from "containers/Blogs/BlogListingPage";
import BlogPage from "containers/Blogs/BlogPage";

export const pages = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  {
    path: GET_PROPERTY_DETAIL_URL + ":property_slug/",
    component: PropertyDetailPage, 
  },
  { path: "/properties", component: ListingPropertyPage },
  
  { path: BOOK_NOW_PAGE_URL, component: BookNowForm },
  {path: BLOG_LISTING_PAGE_URL, component: BlogListingPage},
  {path: BLOG_PAGE_URL , component: BlogPage},

  { path: CHECKOUT_PAGE_URL, component: CheckOutPage },
  { path: PAYMENT_STATUS_PAGE_URL, component: PayPage },
];


const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const [allPropertiesData, setAllPropertiesData] = useState({});
  const [data, Error, isLoading, getPropertyFiltersData] =
    useGetPropertyFiltersData();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    getPropertyFiltersData();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />

      <SiteHeader 
        filtersData={data ? data : {}}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          const extraData =
            component === ListingPropertyPage
              ? {
                  data: data,
                  queryParams: queryParams,
                  setQueryParams: setQueryParams,
                  allPropertiesData: allPropertiesData,
                  setAllPropertiesData: setAllPropertiesData,
                }
              : {};
          return (
            <Route
              key={path}
              element={<Component {...extraData} />}
              path={path}
            />
          );
        })}
        <Route element={<Page404 />} />
      </Routes>
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
