export const GET_PROPERTY_DETAIL_URL = "/blr/";
export const PROPERTIES_LIST_PAGE_URL = "/properties";


// Checkout page urls
export const CHECKOUT_PAGE_URL = "/:order_id/checkout/"
export const PAYMENT_STATUS_PAGE_URL = "/:order_id/payment/"
export const BOOK_NOW_PAGE_URL = "/book-now/";
export const BLOG_LISTING_PAGE_URL = "/blog";
export const BLOG_PAGE_URL = "/blog/:blog_id/";

