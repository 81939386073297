import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import { GET_PROPERTY_DETAIL_URL } from "routers/constants";
import { RUPEE_SYMBOL } from "constants/symbols";
import { trackEvent } from "services/analyticsService";
import { TRACK_COZIQ_SPACE_CATEGORY, TRACK_VIEW_SPACES_BTN_ACTION, TRACK_VIEW_SPACES_BTN_LABEL } from "analytics/constants";


const PropertyCard = ({ size = "default", className = "", data }) => {
  const {
    photos,
    name,
    price,
    id,
    area_name,
    city_name,
    slug,
    reviews_count,
    rating,
  } = data;
  const href = `${GET_PROPERTY_DETAIL_URL}${slug}/`;

  const trackPropertyClicked = () => {
    trackEvent({
      category: TRACK_COZIQ_SPACE_CATEGORY,
      action: TRACK_VIEW_SPACES_BTN_ACTION,
      label: TRACK_VIEW_SPACES_BTN_LABEL,
    });
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`PropertyCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={photos ? photos : []}
          href={href ? href : ""}
          onClick={trackPropertyClicked}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {listingCategory.name} · {bedrooms} beds
          </span> */}
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{`${area_name}, ${city_name}`}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            Starts @{` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                {RUPEE_SYMBOL}
                {price}
              </span>
            )}
          </span>
          {!!4.5 && <StartRating point={rating} />}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default PropertyCard;
