// Categories
export const TRACK_BTN_CATEGORY = "Buttons";
export const TRACK_COZIQ_SPACE_CATEGORY = "CoziQ Space";


export const TRACK_VIEW_SPACES_BTN_LABEL = "View spaces Button";
export const TRACK_VIEW_SPACES_BTN_ACTION = "View spaces Clicked";


export const TRACK_COZIQ_SPACE_BOOKNOW_ACTION = "Book Now";
