import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as MUITimePicker } from "@mui/x-date-pickers/TimePicker";

export default function TimePicker({...props}) {
  const timePickerRef = React.useRef(null);

  React.useEffect(() => {
    if (timePickerRef.current) {
      timePickerRef.current.placeholder = "HH:MM AA";
      timePickerRef.current.style.textTransform = "uppercase";
      timePickerRef.current.style.fontSize = "0.875rem";
    }
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUITimePicker
        // props={props}
        ampm
        ampmInClock
        inputRef={timePickerRef}
        sx={{
          width: "100%",
          "& .MuiInputLabel-root.Mui-focused": { color: "#979797"}, //styles the label
          "& .MuiInputBase-input": {
            "--tw-ring-opacity": "0",
            "--tw-border-opacity" : "1",
            "--tw-ring-color":
            "rgba(var(--c-primary-200), var(--tw-ring-opacity))",
          }, //styles the input
          "& .MuiOutlinedInput-root": {
            "&:hover > fieldset": {
              borderColor: "rgba(var(--c-primary-200), var(--tw-ring-opacity))",
            },
            height: "2.75rem",
            borderRadius: "1rem",
            borderColor: "rgba(var(--c-primary-300), var(--tw-border-opacity))",
          },
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "rgba(226, 232, 240, var(--tw-border-opacity))",
            },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
