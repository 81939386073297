import { useState, useEffect } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { BOOK_NOW_PAGE_URL } from "routers/constants";
import useRetrieveProperty from "containers/PropertyDetailPage/data/useRetrieveProperty";
import { useParams } from "react-router-dom";

const MobileFooterSticky = () => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));
  const [data, Error, isLoading, retrieveProperty] = useRetrieveProperty();
  const { property_slug } = useParams();

  useEffect(() => {
    retrieveProperty(property_slug);
  }, []);

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-l font-medium">
            What are you
            {/* <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span> */}
          </span>
          <span className="block text-l font-medium">Waiting For?</span>

          {/* <ModalSelectDate
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString([startDate, endDate])}
              </span>
            )}
          /> */}
        </div>
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              // onClick={openModal}
              href={BOOK_NOW_PAGE_URL + `?p_slug=${property_slug}`}
              // targetBlank
            >
              Book Now
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
