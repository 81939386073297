import { FC } from "react";
import ReactGA from "react-ga4";

const MEASUMENT_ID="G-BFTE6J33TT"

ReactGA.initialize(MEASUMENT_ID);

export const setAnalytics = (props: any) => {
    ReactGA.set(props)
}

export const pageViewAnalytics = (props: any) => {
    ReactGA.send(
        { hitType: "pageview", page: props }
    )
}

interface trackEventInterface {
    category: string,
    action: string,
    label?: string,
    value?: number,
    nonInteraction?: boolean,
    transport?: "beacon" | "xhr" | "image" | undefined
}

export const trackEvent = (props: trackEventInterface) => {
    ReactGA.event(props);
}