import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import useGetBlog from "./data/useGetBlog";
import parse from "html-react-parser";
import NcImage from "shared/NcImage/NcImage";

const BlogPage = () => {
  const [data, isBlogError, isBlogLoading, getBlog] = useGetBlog();
  const { blog_id } = useParams();

  useEffect(() => {
    getBlog(blog_id);
  }, []);

  const renderHeader = () => {
    return (
      data && (
        <div>
          <header className="container rounded-xl">
            <div className="max-w-screen-md mx-auto space-y-5">
              <h1
                key={data.id}
                className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
              >
                {data.title}
              </h1>
              <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                {data.description}
              </span>
            </div>
          </header>
          <NcImage
            className="w-full rounded-xl"
            containerClassName="container my-10 sm:my-12 "
            src={data.thumbnail}
          />
        </div>
      )
    );
  };

  const renderContent = () => {
    return (
      data && (
        <div
          id="single-entry-content"
          className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        >
          {parse(data.body)}
        </div>
      )
    );
  };

  const renderAuthor = () => {
    return (
      data && (
        <div className="max-w-screen-md mx-auto ">
          <div className="nc-SingleAuthor flex">
            <Avatar
              sizeClass="w-11 h-11 md:w-20 md:h-20 mb-6 text-2xl"
              radius="rounded-full"
              userName={data.author}
              imgUrl={data.avatar}
            />
            <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1 ">
              <span className="text-xs text-neutral-400 uppercase tracking-wider">
                WRITEN BY
              </span>
              <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                <p>{data.author}</p>
              </h2>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/* <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div> */}
        {renderAuthor()}
      </div>
    </div>
  );
};

export default BlogPage;
