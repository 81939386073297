import React, { useEffect, useState } from "react";
import PropertyCard from "components/PropertyCard/PropertyCard.jsx";
import Pagination from "shared/PaginationJsx/index.jsx";
import TabFilters from "./PropertyFilters";
import Heading2 from "components/Headline/Heading2";
import useListProperties from "./data/useListProperties";
import { useSearchParams } from "react-router-dom";
import { PROPERTY_LISTING_PAGE_SIZE } from "containers/ListingPropertyPage/constants";
import { getParamsDict, length } from "commons/utils/helpers";
import Badge from "shared/Badge/Badge";
import { CITY_FILTER_NAME } from "constants/property";
import Skeleton from "@mui/material/Skeleton";

const SectionGridFilterCard = ({
  className,
  allPropertiesData,
  setAllPropertiesData,
  filtersData,
  queryParams,
  setQueryParams,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, Error, isLoading, listProperties] = useListProperties();
  const [results, setResults] = useState([]);
  let [queryParamsPage, setQueryParamsPage] = useState();
  const [firstActiveIndex, setFirstActiveIndex] = useState(1);
  const [heading, setHeading] = useState("Private Dining");
  const [filtersApplied, setFiltersApplied] = useState(false);

  const propertySkeleton = 16;

  const getCityName = (latestFiltersData, city_id) => {
    if (latestFiltersData.cities) {
      return latestFiltersData.cities
        .filter((city) => city.id == city_id)
        .map((item) => item.name || "");
    }
  };

  const getAreaName = (latestFiltersData, city_id, area_id) => {
    if (latestFiltersData.city_areas && filtersData.city_areas[city_id]) {
      return latestFiltersData.city_areas[city_id]
        .filter((area) => area.id == area_id)
        .map((item) => item.name || "");
    }
  };

  const updateHeadingText = () => {
    const currentCityName = getCityName(filtersData, queryParams.city);
    const currentAreaName = getAreaName(
      filtersData,
      queryParams.city,
      queryParams.area
    );
    let text = "CoziQ Spaces";
    const currentCityExists = currentAreaName ? currentCityName.length : false;
    const currentAreaExists = currentAreaName ? currentAreaName.length : false;
    text = currentAreaExists || currentCityExists ? (text += " in ") : text;
    text = currentAreaExists ? (text += `${currentAreaName}, `) : text;
    text = currentCityName ? (text += `${currentCityName}`) : text;
    setHeading(text);
  };

  useEffect(() => {
    // For setting the heading on first Time(filtersData).
    updateHeadingText();
  }, [filtersData, queryParams]);

  // API Call
  useEffect(() => {
    // Checking if page is present in the url.
    const page = searchParams.get("page") ? searchParams.get("page") : 1;
    setFirstActiveIndex(page);
    setQueryParamsPage(page);
    setQueryParams({ ...getParamsDict(searchParams), city: 1 });
  }, []);

  // When Page number is changed...
  useEffect(() => {
    if (queryParamsPage) {
      setSearchParams({
        ...getParamsDict(searchParams),
        page: queryParamsPage,
      });
      if (queryParamsPage in allPropertiesData) {
        // console.log("Cached Data :: ")
        setResults(allPropertiesData[queryParamsPage]);
      } else {
        callListPropertiesAPI();
      }
    }
  }, [queryParamsPage]);

  const callListPropertiesAPI = () => {
    listProperties({
      ...getParamsDict(searchParams),
      page: queryParamsPage,
      city: 1,
    });
    // console.log("API Call Data :: ")
  };

  useEffect(() => {
    if (Object.keys(allPropertiesData).length === 0) {
      callListPropertiesAPI();
    }
  }, [allPropertiesData]);

  const cleanQueryParams = (baseQueryParams) => {
    let cleanedQueryParams = { ...baseQueryParams };
    Object.keys(baseQueryParams).map((key) => {
      if (baseQueryParams[key] === "") {
        delete cleanedQueryParams[key];
      }
    });
    return cleanedQueryParams;
  };

  const updateFiltersExist = (queryParamsData) => {
    let filtersExist = false;
    const IGNORE_FILTERS = [CITY_FILTER_NAME, "page"];
    if (queryParams) {
      Object.keys(queryParams).map((key) => {
        if (!IGNORE_FILTERS.includes(key) && length(queryParams[key])) {
          filtersExist = true;
        }
      });
    }
    setFiltersApplied(filtersExist);
  };

  useEffect(() => {
    // Checking if page is present in the url.
    if (Object.keys(queryParams).length) {
      const cleanedQueryParams = cleanQueryParams({ ...queryParams });
      setSearchParams({
        ...getParamsDict(searchParams),
        ...queryParams,
        page: 1,
      });
      // Set AllPropertiesData to empty to reset pages data.
      setAllPropertiesData({});
      setQueryParamsPage(1);
      setFirstActiveIndex(1);
    }
    updateFiltersExist(queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (data) {
      setResults(data.results);
      let currentPageResults = {
        [queryParamsPage]: data.results,
      };
      setAllPropertiesData({ ...allPropertiesData, ...currentPageResults });
    }
  }, [data]);

  const handlePageChange = (page_number) => {
    setQueryParamsPage(page_number);
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading={heading} propertiesCount={data && data.total_count} />

      <div className="mb-8 lg:mb-11">
        <TabFilters
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          filtersData={filtersData}
        />
      </div>
      <div className="mb-3"> 
        {filtersApplied && <Badge name="Filters Selected" />}
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {!isLoading && length(results)
          ? results.map((property) => (
              <PropertyCard key={property.id} data={property} />
            ))
          : [...Array(propertySkeleton)].map((_, i) => (
              <Skeleton className="aspect-w-4 aspect-h-3 rounded-2xl" variant="rectangular" key={i} />
            ))
           
        }
      </div>

      <div className="grid gap-4">
        {!isLoading && !length(results) ? (
          <>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200 font-bold">
              Oops! it’s time to relax, the filters.
            </span>
            <span className="block font-small text-neutral-700 dark:text-neutral-200">
              Kindly change or remove few filters applied to look at more spaces
              in your preferred locality.
            </span>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="flex mt-16 justify-center items-center">
        {data && (
          <Pagination
            page_size={PROPERTY_LISTING_PAGE_SIZE}
            total_count={data.total_count}
            handlePageChange={handlePageChange}
            firstActiveIndex={firstActiveIndex}
          />
        )}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
