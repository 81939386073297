import React, { useState, useEffect } from "react";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import DatesRangeInput from "./DatesRangeInput";
import converSelectedDateToString from "utils/converSelectedDateToString";
import Checkbox from "commons/Checkbox/Checkbox";
import { SPACE_CATEGORY_FILTER_NAME, PERMIT_TYPE_FILTER_NAME } from "constants/property";


const PropertySearchPageForm = ({
  filtersData,
  queryParams,
  setQueryParams,
  selectedSearchData,
  setSelectedSearchData,
  finalSelectedFilters,
}) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState("locationPickup");
  //
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate] = useState(new Date("2023/02/06"));
  const [endDate] = useState(new Date("2023/02/23"));

  const [dropOffLocationType, setDropOffLocationType] = useState("Round-trip");
  const [flightClassState, setFlightClassState] = useState("Economy");

  const [guestInput, setGuestInput] = useState({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });

  // Managing City, Area and Max Guests Filters
  const [cityAreasSuggestions, setCityAreasSuggestions] = useState([]);
  const allCities = filtersData.cities;
  const [allCityAreas, setAllCityAreas] = useState([]);

  useEffect(() => {
    if (filtersData) {
      setAllCityAreas(filtersData.city_areas);
    }
  }, [filtersData]);

  useEffect(() => {
    if (allCityAreas && selectedSearchData) {
      let currentCity = selectedSearchData.city;
      if (!currentCity) {
        currentCity = queryParams ? queryParams["city"] : undefined;
      }
      setCityAreasSuggestions(allCityAreas[currentCity]);
    }
  }, [selectedSearchData, allCityAreas]);

  const handleSearchDataChange = (newSearchData) => {
    // When filters updated in another component, this method is called...

    let finalSearchData = { ...selectedSearchData, ...newSearchData };
    if ("maximum_guests" in newSearchData) {
      // If maximum_guests are 0, then the filter should become empty
      if (newSearchData.maximum_guests == 0) {
        finalSearchData.maximum_guests = "";
      }
    }

    if ("city" in newSearchData && !newSearchData.city) {
      // If city is cleared, then area should automatically be cleared.
      finalSearchData.area = "";
    }

    setSelectedSearchData(finalSearchData);
  };

  // Managing Type Of Spaces, Permits Filters

  const [typeOfSpaces, setTypeOfSpaces] = useState([]);
  const [allPermitTypes, setAllPermitTypes] = useState([]);
  const [selectedSpaceCategoryFilters, setSelectedSpaceCategoryFilters] =
    useState({});
  const [selectedPermitTypes, setSelectedPermitTypes] = useState({});

  const setDefaultSelectedFilters = (filterName, setFilterData) => {
    // This is for setting the default values of filters for the first time.
    const selectedFilters =
      queryParams && queryParams[filterName]
        ? queryParams[filterName].split(",")
        : [];
    if (selectedFilters) {
      const defaultSelectedFilters = {};
      selectedFilters.map((spaceCategoryId) => {
        defaultSelectedFilters[spaceCategoryId] = "";
      });
      setFilterData(defaultSelectedFilters);
    }
  };

  useEffect(() => {
    // Default selected filters for Space Categories
    setDefaultSelectedFilters(
      SPACE_CATEGORY_FILTER_NAME,
      setSelectedSpaceCategoryFilters
    );
    setDefaultSelectedFilters(PERMIT_TYPE_FILTER_NAME, setSelectedPermitTypes);
  }, [queryParams]);

  useEffect(() => {
    setTypeOfSpaces(filtersData ? filtersData.space_categories : []);
    setAllPermitTypes(filtersData ? filtersData.permits : []);
  }, [filtersData]);

  const getFormatedCheckboxFiltersData = (selectedFilters, queryParamKey) => {
    const filterTypeSelectedIds = Object.keys(selectedFilters).toString();
    return { [queryParamKey]: filterTypeSelectedIds };
  };

  const handleFilterCheckboxChange = (
    event,
    alreadySelectedFilters,
    setFiltersCallback,
    item
  ) => {
    const isChecked = event.target.checked;
    const value = event.target.value;
    const updatedSelectedFilters = { ...alreadySelectedFilters };
    if (isChecked) {
      updatedSelectedFilters[value] = item.name;
    } else {
      if (value in updatedSelectedFilters) {
        delete updatedSelectedFilters[value];
      }
    }
    setFiltersCallback(updatedSelectedFilters);
  };

  useEffect(() => {
    setSelectedSearchData({
      ...selectedSearchData,
      ...getFormatedCheckboxFiltersData(
        selectedSpaceCategoryFilters,
        SPACE_CATEGORY_FILTER_NAME
      ),
    });
  }, [selectedSpaceCategoryFilters]);

  useEffect(() => {
    setSelectedSearchData({
      ...selectedSearchData,
      ...getFormatedCheckboxFiltersData(
        selectedPermitTypes,
        PERMIT_TYPE_FILTER_NAME
      ),
    });
  }, [selectedPermitTypes]);

  const renderInputCity = () => {
    const isActive = fieldNameShow === "City";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("City")}
          >
            <span className="text-neutral-400">City</span>
            <span className="border-primary-500 bg-primary-50 text-primary-700">
              {finalSelectedFilters &&
                finalSelectedFilters.city &&
                `${allCities
                  .filter((city) => city.id == finalSelectedFilters.city)
                  .map((item) => item.name)}`}
            </span>
          </button>
        ) : (
          <LocationInput
            headingText="City"
            suggestionsData={allCities ? allCities : []}
            handleSearchDataChange={handleSearchDataChange}
            searchKeyName="city"
            defaultValue={finalSelectedFilters ? finalSelectedFilters.city : ""}
          />
        )}
      </div>
    );
  };

  const renderInputArea = () => {
    const isActive = fieldNameShow === "Area";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("Area")}
          >
            <span className="text-neutral-400">Area</span>
            {/* <span>{locationInputPickUp || "Location"}</span> */}
            <span className="border-primary-500 bg-primary-50 text-primary-700">
              {finalSelectedFilters &&
                finalSelectedFilters.area &&
                `${
                  (allCityAreas[finalSelectedFilters.city] &&
                    allCityAreas[finalSelectedFilters.city]
                      .filter((area) => area.id == finalSelectedFilters.area)
                      .map((item) => item.name)) ||
                  ""
                }`}
            </span>
          </button>
        ) : (
          <LocationInput
            headingText="Area"
            onChange={(value) => {
              setFieldNameShow("dates");
            }}
            suggestionsData={cityAreasSuggestions ? cityAreasSuggestions : []}
            handleSearchDataChange={handleSearchDataChange}
            searchKeyName="area"
            searchSuggestionsEmptyMsg="Please Select City First!"
            defaultValue={finalSelectedFilters ? finalSelectedFilters.area : ""}
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">Drop off</span>
            <span>{locationInputDropOff || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Drop off?"
            defaultValue={locationInputDropOff}
            onChange={(value) => {
              setLocationInputDropOff(value);
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest =
        (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} guests`;
    }

    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Guests</span>
            <span
              className={`${
                finalSelectedFilters && finalSelectedFilters.maximum_guests
                  ? "border-primary-500 bg-primary-50 text-primary-700"
                  : ""
              }`}
            >
              {(finalSelectedFilters && finalSelectedFilters.maximum_guests) ||
                "Add "}{" "}
              Guests
            </span>
          </button>
        ) : (
          <GuestsInput
            onChange={setGuestInput}
            handleSearchDataChange={handleSearchDataChange}
            searchKeyName="maximum_guests"
            defaultValue={
              finalSelectedFilters ? finalSelectedFilters.maximum_guests : ""
            }
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Flight type?</span>
            <span>{`${dropOffLocationType}, ${flightClassState}`}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Flight type?
            </span>
            <div className="relative mt-5">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "Round-trip"
                      ? "bg-black shadow-black/10 shadow-lg text-white"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => setDropOffLocationType("Round-trip")}
                >
                  Round-trip
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${
                    dropOffLocationType === "One-way"
                      ? "bg-black text-white shadow-black/10 shadow-lg"
                      : "border border-neutral-300 dark:border-neutral-700"
                  }`}
                  onClick={(e) => setDropOffLocationType("One-way")}
                >
                  One-way
                </div>
              </div>

              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Ticket Class:
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderRadio("class", "Economy", "Economy")}
                  {renderRadio("class", "Business", "Business")}
                  {renderRadio("class", "Multiple", "Multiple")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (name, id, label, defaultChecked) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={flightClassState === label}
          id={id + name}
          name={name}
          onChange={() => setFlightClassState(label)}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderCheckBox = (items, selectedFilters, setSelectedFilters, filterName) => {
    return (
      <div className="relative flex flex-col px-2 py-2 space-y-5">
        {/* <div className="flex items-center "> */}
        {items &&
          items.map((item) => (
            <div key={item.id} className="">
              <Checkbox
                name={item.name}
                label={item.name}
                value={item.id}
                onChange={(e) =>
                  handleFilterCheckboxChange(
                    e,
                    selectedFilters,
                    setSelectedFilters,
                    item
                  )
                }
                defaultChecked={
                  finalSelectedFilters &&
                  finalSelectedFilters[filterName] &&
                  finalSelectedFilters[filterName].includes(String(item.id))
                    ? true
                    : false
                }
              />
            </div>
          ))}
      </div>
    );
  };

  const renderSpaceTypes = () => {
    const isActive = fieldNameShow === "spaceTypes";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("spaceTypes")}
          >
            <span className="text-neutral-400">Type Of Spaces</span>
            <span className="border-primary-500 bg-primary-50 text-primary-700">
              {`${typeOfSpaces
                .filter((space) =>
                  Object.keys(selectedSpaceCategoryFilters).includes(
                    space.id.toString()
                  )
                )
                .map((item) => {
                  return item.name;
                })
                .slice(0, 2)}`}
            </span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Type of Spaces
            </span>
            <div className="relative mt-5">
              <div className="mt-6">
                {/* <label className="text-base font-semibold" htmlFor="">
                  Ticket Class:
                </label> */}
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderCheckBox(
                    typeOfSpaces,
                    selectedSpaceCategoryFilters,
                    setSelectedSpaceCategoryFilters,
                    SPACE_CATEGORY_FILTER_NAME
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPermitTypes = () => {
    const isActive = fieldNameShow === "PermitTypes";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4 `}
            onClick={() => setFieldNameShow("PermitTypes")}
          >
            <span className="text-neutral-400">Permits</span>
            <span className="border-primary-500 bg-primary-50 text-primary-700">
              {`${allPermitTypes
                .filter((permit) =>
                  Object.keys(selectedPermitTypes).includes(
                    permit.id.toString()
                  )
                )
                .map((item) => {
                  return item.name;
                })
                .slice(0, 2)}`}
            </span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Permits
            </span>
            <div className="relative mt-5">
              <div className="mt-6">
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderCheckBox(
                    allPermitTypes,
                    selectedPermitTypes,
                    setSelectedPermitTypes,
                    PERMIT_TYPE_FILTER_NAME
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {/* {renderInputCity()} */}
        {renderInputArea()}
        {/*  */}

        {/*  */}
        {/* {renderInputDates()} */}
        {/*  */}
        {renderInputGuests()}
        {/* {renderGenerals()} */}
        {renderSpaceTypes()}
        {renderPermitTypes()}
      </div>
    </div>
  );
};

export default PropertySearchPageForm;
