import dayjs from "dayjs";


export const getParamsDict = (searchParams) => {
    const paramsDict = {}
    for (const [key, value] of searchParams.entries()) {
        paramsDict[key] = value
    }
    return paramsDict
}

export const length = (variable) => {
    if (variable) {
        const isArray = Object.prototype.toString.call(variable).indexOf("Array")>-1;
        const isObject = Object.prototype.toString.call(variable).indexOf("Object")>-1;
        const isNumber = Object.prototype.toString.call(variable).indexOf("Number")>-1;
        if (isArray){
            return variable.length;
        }
        else if (isObject) {
            return Object.keys(variable).length;
        }
        else if (isNumber) {
            return String(variable).length;
        }
        else {
            return variable.length
        }
    }
    return 0;
}; 

export const convertUnicode = (input) => {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a,b) =>
      String.fromCharCode(parseInt(b, 16)));
  }

export const formatDate = (date) => {
    let formatted_date = dayjs(date).format("YYYY-MM-DD")
    return formatted_date
    
}