import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import Typewriter from "typewriter-effect";
import { PROPERTIES_LIST_PAGE_URL } from "routers/constants";
import { trackEvent } from "services/analyticsService";
import {
  TRACK_VIEW_SPACES_BTN_ACTION,
  TRACK_VIEW_SPACES_BTN_LABEL,
} from "analytics/constants";

const SectionHero = ({ className = "" }) => {
  const textLoop = [
    "Dining!",
    "Theatre!",
    "Workspace!",
    "Moments!",
    "Memories!",
    "Time!",
  ];

  const traceViewSpaceButton = () => {
    trackEvent({
      category: TRACK_VIEW_SPACES_BTN_LABEL,
      action: TRACK_VIEW_SPACES_BTN_ACTION,
      label: TRACK_VIEW_SPACES_BTN_LABEL
    });
  };

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h4 className="font-normal text-2xl md:text-3xl xl:text-8l !leading-[114%] ">
            Experience the JOY of
          </h4>
          <span className="font-semibold text-3xl md:text-4xl xl:text-5xl !leading-[100%] ">
            Private {` `}
            <div className="inline-block">
              <Typewriter
                options={{
                  strings: textLoop,
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </span>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            A uniquely decorated private space, just for you! Get away from the
            hustle and bustle of the city. Enjoy a pleasant and cozy atmosphere
            for your gathering with food and music of your choice!
          </span>
          <ButtonPrimary
            href={PROPERTIES_LIST_PAGE_URL}
            onClick={traceViewSpaceButton}
          >
            View Spaces
          </ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full"></div>
    </div>
  );
};

export default SectionHero;
