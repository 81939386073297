import * as React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DatePicker(props) {
  const timePickerRef = React.useRef(null);

  // React.useEffect(() => {
  //   if (timePickerRef.current) {
  //     timePickerRef.current.placeholder = "HH:MM AA";
  //     timePickerRef.current.style.textTransform = "uppercase";
  //     timePickerRef.current.style.fontSize = "0.875rem";
  //   }
  // });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatePicker
        sx={{
          width: "100%",
          "& .MuiInputLabel-root.Mui-focused": { color: "#979797"}, //styles the label
          "& .MuiInputBase-input": {
            "--tw-ring-opacity": "0",
            "--tw-border-opacity" : "1",
            "--tw-ring-color":
            "rgba(var(--c-primary-200), var(--tw-ring-opacity))",
          }, //styles the input
          "& .MuiOutlinedInput-root": {
            "&:hover > fieldset": {
              borderColor: "rgba(var(--c-primary-200), var(--tw-ring-opacity))",
            },
            height: "2.75rem",
            borderRadius: "1rem",
            borderColor: "rgba(var(--c-primary-300), var(--tw-border-opacity))",
          },
          "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "rgba(226, 232, 240, var(--tw-border-opacity))",
            },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
