import SectionHero from "components/SectionHero/SectionHero.jsx";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionWhyThinnai from "components/SectionThreePicturesAndText/SectionWhyThinnai";
import SectionHowItWorks from "components/SectionThreePicturesAndText/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

const DEMO_CATS = [
  {
    id: "1",
    href: "#",
    name: "Guest @Elixir",
    taxonomy: "category",
    count: 188288,
    review:
      "Literally we were bored of visiting restaurants every Weekend. It was such a Wonderful Experience, We felt like sitting there forever. CoziQ is such an Amazing concept.",
    thumbnail: require("images/moments/1.png"),
  },
  {
    id: "2",
    href: "#",
    name: "Guest @Breezy Cove",
    taxonomy: "category",
    count: 188288,
    review:
      "We sincerely appreciate the Quality of Service, customer support, the level of detailing and accountability your team has! Kudos to CoziQ.",
    thumbnail: require("images/moments/2.png"),
  },
  {
    id: "2",
    href: "#",
    name: "Guest @Artsy Willow",
    taxonomy: "category",
    count: 188288,
    review:
      "It was an amazing experience at CoziQ, spent quality time with my friend. Thank you for coming up with such a beautiful concept.    ",
    thumbnail: require("images/moments/3.png"),
  },
  {
    id: "2",
    href: "#",
    name: "Guest @Urban Patio",
    taxonomy: "category",
    count: 188288,
    review:
      "CoziQ experience is something I'd cherish forever. One of the best dates of my life, Thank you, Team CoziQ.",
    thumbnail: require("images/moments/4.png"),
  },
  {
    id: "2",
    href: "#",
    name: "Guest @Cosmos",
    taxonomy: "category",
    count: 188288,
    review:
      "I have no words to express how amazing the CoziQ experience was. I am never going to a restaurant or a cafe to dine out. It’s going to be CoziQ everytime.",
    thumbnail: require("images/moments/5.png"),
  },
  {
    id: "2",
    href: "#",
    name: "Guest@Queen's Throne",
    taxonomy: "category",
    count: 188288,
    review:
      "My Friends were blown away by the concept of CoziQ and this is the most memorable Birthday ever for me.",
    thumbnail: require("images/moments/6.png"),
  },
];

const DEMO_CATS_2 = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-4 lg:pb-16" />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />

        {/* SECTION2 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-purple-50 dark:bg-black dark:bg-opacity-20" />
          <SectionOurFeatures />
        </div>

        <SectionWhyThinnai />

        <SectionHowItWorks />
        <div className="relative py-2">
          <BackgroundSection className="bg-purple-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionBecomeAnAuthor />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
