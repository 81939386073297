// export const API_BASE_URL = "http://43.204.147.163"
export const API_BASE_URL = process.env.REACT_APP_API_URL || "http://api.bookmythinnai.com"
// export const API_BASE_URL = "https://api.bookmythinnai.com"


// Properties
export const LIST_PROPERTIES_URL = API_BASE_URL + "/property/api/list/"
export const RETRIEVE_PROPERTY_API_URL = API_BASE_URL + "/property/api/retrieve/:property_slug/"
export const GET_PROPERTY_FILTERS_DATA_API_URL = API_BASE_URL + "/property/api/get-property-filters-data/"

// Payments Urls
export const VERIFY_GUEST_PAYMENT_API_URL = API_BASE_URL + "/payments/api/verify-guest-payment/"

// Order APIs
export const GET_ORDER_PAYMENT_DETAILS_API_URL = API_BASE_URL + "/orders/api/get-order-payment-details/:order_id/"
// Book now form
export const GET_BOOK_NOW_FORM_GROUP_TYPE_API_URL = API_BASE_URL + "/property/api/get-book-now-form-data/"
export const CREATE_ORDER_API_URL = API_BASE_URL + "/orders/api/create-order/"

// Blog APIs
export const LIST_BLOG_API_URL = API_BASE_URL + "/blogs/api/posts/"
export const GET_BLOG_API_URL = API_BASE_URL + "/blogs/api/posts/:blog_id/"