import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "components/ListingPropertySearchForm/ClearDataButton";
import React, { useState, useEffect, useRef, FC } from "react";

const LocationInput = ({
  className = "",
  defaultValue,
  headingText,
  suggestionsData = [],
  handleSearchDataChange,
  searchKeyName,
  searchSuggestionsEmptyMsg = "No Record Found!",
}) => {
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleSelectLocation = (item) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    // setTimeout(() => {
    //   setValue(item.name);
    //   handleSearchDataChange({ [searchKeyName]: item.id });
    // }, 0);
    setValue(item.name);
    handleSearchDataChange({ [searchKeyName]: item.id });
  };

  const setDefaultValues = () => {
    suggestionsData.map((item) => {
      if (item.id == defaultValue) {
        setValue(item.name);
      }
    });
  };
  useEffect(() => {
    // Setting the default Values
    if (suggestionsData && defaultValue) {
      setDefaultValues();
    }
  }, [defaultValue, suggestionsData]);

  const handleOnChange = (e) => {
    setValue(e.currentTarget.value);
  };

  const handleClearInput = () => {
    setValue("");
    handleSearchDataChange({ [searchKeyName]: "" });
  };

  const renderSearchValues = ({ heading, items }) => {
    const filteredData = () => {
      return suggestionsData.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(value.toLowerCase())
      );
    };

    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {filteredData().length > 0 ? (
            filteredData().map((item) => {
              return (
                <div
                  className="py-2 mb-1 flex items-center space-x-3 text-sm"
                  onClick={() => handleSelectLocation(item)}
                  key={item.id}
                >
                  <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span className="">{item.name}</span>
                </div>
              );
            })
          ) : (
            <span className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 dark:hover:bg-neutral-700 cursor-pointer">
              <span className="block font-small text-neutral-700 dark:text-neutral-200">
                {searchSuggestionsEmptyMsg}
              </span>
            </span>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Search destinations"}
            value={value}
            onChange={(e) => handleOnChange(e)}
            ref={inputRef}
          />
          {value && (
            <span className="absolute right-8 top-1/2 -translate-y-1/2">
              <ClearDataButton onClick={handleClearInput} className="" />
            </span>
          )}
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: "Locations",
                items: [
                  "Afghanistan",
                  "Albania",
                  "Algeria",
                  "American Samao",
                  "Andorra",
                ],
              })
            : renderSearchValues({
                heading: "Popular destinations",
                items: [
                  "Australia",
                  "Canada",
                  "Germany",
                  "United Kingdom",
                  "United Arab Emirates",
                ],
              })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
