import { CustomLink } from "data/types";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import Button from "shared/Button/Button";
import twFocusClass from "utils/twFocusClass";

const DEMO_PAGINATION = [
  {
    label: "2",
    href: "#",
  },
  {
    label: "3",
    href: "#",
  },
  {
    label: "4",
    href: "#",
  },
  {
    label: "5",
    href: "#",
  },
];

const Pagination = ({ page_size, total_count, handlePageChange, firstActiveIndex = 1, className = "" }) => {

  const [activePageIndex, setActivePageIndex] = useState(parseInt(firstActiveIndex));

  const calculateTotalPages = (page_size, total_count) => {
    return Math.ceil(total_count/page_size);
  };

  const totalPages = calculateTotalPages(page_size, total_count);

  const handlePageNumberClick = (index) => {
    if (activePageIndex !== index)
    {
        setActivePageIndex(index);
        handlePageChange(index);
    }
  };

  const renderItem = (index) => {
    if (index === activePageIndex) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          onClick={() => handlePageNumberClick(index)}
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {index}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Button
        key={index}
        onClick={() => handlePageNumberClick(index)}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
      >
        {index}
      </Button>
    );
  };

  const getPagesContent = (total_pages) => {
    let content = [];
    for (let i = 1; i <= total_pages; i++) {
      content.push(
        renderItem(i)
      );
    }
    return content;
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {getPagesContent(totalPages)}
    </nav>
  );
};

export default Pagination;
