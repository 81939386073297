import useAPICall from "hooks/useAPICall";
import {LIST_PROPERTIES_URL} from "constants/apis"
import {PROPERTY_LISTING_PAGE_SIZE} from "containers/ListingPropertyPage/constants"


const useListProperties = () => {
  const [data, error, isLoading, callListProperties, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData(res.data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback
    }
  ];

  const listProperties = (params) => {
    const url = LIST_PROPERTIES_URL;
    params["page_size"] = PROPERTY_LISTING_PAGE_SIZE;
    callListProperties({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      params,
    });
  };
  return [data, error, isLoading, listProperties, setSuccessData, setError];
};

export default useListProperties;
