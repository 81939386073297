import React from "react";

const FormSuccess = () => {
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulation 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <h4 className="text-xl lg:text-xl ">
          Thank you for your interest in choosing CoziQ. We have shared your
          response with the Host. We will get back to you ASAP. Thankyou for
          your patience.
        </h4>
        <div className="flex items-center justify-center">
        <a
        href="https://wa.me/918050833460"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center bg-green-500 text-white rounded-full h-12 w-64 shadow-lg hover:bg-green-600"
      >
        <i className="lab la-whatsapp text-xl mr-2"></i>
        <span>Chat with our Expert</span>
      </a>
        </div>
        
      </div>
    );
  };
  return (
    <div className="">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
      
    </div>
  );
};

export default FormSuccess;
