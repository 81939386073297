import React, { ReactNode } from "react";
// import { imageGallery as listingStayImageGallery } from "./constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import MobileFooterSticky from "./(components)/MobileFooterSticky";

const DetailPagetLayout = ({ modalPhotos, children }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    
    navigate(`${thisPathname}?${params.toString()}`);
  };
  
  const getImageGalleryListing = () => {
    if (modalPhotos) {
      return modalPhotos.map((item, index) => {
        return {
          id: index,
          url: item,
        };
      });
    }
    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

 
      <MobileFooterSticky />
    </div>
  );
};

export default DetailPagetLayout;
