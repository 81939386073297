"use client";

import React, { FC, useEffect, useState } from "react";
import GuestsInput from "components/ListingPropertySearchForm/GuestsInput";
import LocationInput from "components/ListingPropertySearchForm/LocationInput";
import { useSearchParams } from "react-router-dom";

const PropertySearchForm = ({
  className = "",
  filtersData,
  queryParams,
  setQueryParams,
}) => {
  const [selectedSearchData, setSelectedSearchData] = useState([]);
  const [cityAreasSuggestions, setCityAreasSuggestions] = useState([]);
  const allCities = filtersData.cities;
  const [allCityAreas, setAllCityAreas] = useState([]);

  useEffect(() => {
    if (filtersData) {
      setAllCityAreas(filtersData.city_areas);
    }
  }, [filtersData]);

  useEffect(() => {
    if (allCityAreas && selectedSearchData) {
      let currentCity = selectedSearchData.city;
      if (!currentCity) {
        currentCity = queryParams ? queryParams["city"] : undefined;
      }
      setCityAreasSuggestions(allCityAreas[currentCity]);
    }
  }, [selectedSearchData, allCityAreas]);

  useEffect(() => {
    // Whenever the queryParams change, we are updating the selectedSearchData.
    // selectedSearchData has the info of all the seach values selected.
    if (Object.keys(queryParams).length){
      setSelectedSearchData({...queryParams})
    }
  }, [queryParams])

  const handleSearchDataChange = (newSearchData) => {
    // When filters updated in another component, this method is called...
    let finalSearchData = { ...selectedSearchData, ...newSearchData };

    if ("maximum_guests" in newSearchData) {
      // If maximum_guests are 0, then the filter should become empty
      if (newSearchData.maximum_guests == 0) {
        finalSearchData.maximum_guests = "";
      }
    }

    if ("city" in newSearchData && !newSearchData.city) {
      // If city is cleared, then area should automatically be cleared.
      finalSearchData.area = "";
    }
    setSelectedSearchData(finalSearchData);
  };

  const handleSearchButtonSubmit = () => {
    setQueryParams({ ...queryParams, ...selectedSearchData });
  };


  const renderForm = () => (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      <form className="w-full relative flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          className="flex-[1.5]"
          placeHolder="City"
          desc=""
          suggestionsData={allCities}
          handleSearchDataChange={handleSearchDataChange}
          searchKeyName="city"
          defaultValue={selectedSearchData ? selectedSearchData.city : ""}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <LocationInput
          className="flex-[1.5]"
          placeHolder="Area"
          desc=""
          suggestionsData={cityAreasSuggestions ? cityAreasSuggestions : []}
          handleSearchDataChange={handleSearchDataChange}
          searchKeyName="area"
          searchSuggestionsEmptyMsg="Please Select City First!"
          defaultValue={selectedSearchData ? selectedSearchData.area : ""}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          className="flex-1"
          handleSearchDataChange={handleSearchDataChange}
          searchKeyName="maximum_guests"
          handleSearchButtonSubmit={handleSearchButtonSubmit}
          defaultValue={selectedSearchData ? selectedSearchData.maximum_guests : ""}
        />
      </form>
    </div>
  );

  return (
    <div
      className={`nc-PropertySearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      {renderForm()}
    </div>
  );
};

export default PropertySearchForm;
