import React, { useEffect } from "react";
import Heading from "components/Heading/Heading";
import Card3 from "./Card3";

//Import API Data
import useListBlog from "./data/useListBlog";

const BlogListingPage = () => {
  const [data, isBlogError, isBlogLoading, listBlog] = useListBlog();
  const renderCard = (data) => {
    return (
      <>
        <Card3 key={data.id} className="" post={data} />
      </>
    );
  };

  useEffect(() => {
    listBlog();
  }, []);


  return (
    <div className={`nc-SectionLatestPosts relative py-2 lg:py-4 container`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>Articles </Heading>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {data && data.results.map((blog) => renderCard(blog))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListingPage;
