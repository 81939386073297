import React from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import { BLOG_PAGE_URL } from "routers/constants";
import Avatar from "shared/Avatar/Avatar";

const Card3 = ({ className = "h-full", post }) => {
  const { title, id, thumbnail, author, description, created_at } = post;

  const href = `${BLOG_PAGE_URL.replace(":blog_id", id)}`;
  return (
    <div
      className={`nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="Card3"
    >
      <div className="flex flex-col flex-grow">
        <div className="space-y-5 mb-4">
          <div>
            <h2
              className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
            >
              <Link to={href} className="line-clamp-2" title={title}>
                {title}
              </Link>
            </h2>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-base line-clamp-3">
                {description}
              </span>
            </div>
          </div>
          <div className="flex space-x-4 inline-flex items-center">
            <div className="">
              <Avatar
                sizeClass="h-8 w-8 text-sm"
                radius="rounded-full"
                userName={author}
              />
            </div>
            <div className="flex ">
              <div className="text-sm font-semibold">
                <span>{author}</span>
              </div>
              <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                ·
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 ">
                {created_at}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}
      >
        <Link
          to={href}
          className={`block w-full h-0 aspect-h-9 sm:aspect-h-16 aspect-w-16 `}
        >
          <NcImage
            containerClassName="absolute inset-0"
            src={thumbnail}
            alt={title}
          />
        </Link>
      </div>
    </div>
  );
};

export default Card3;
