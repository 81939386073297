import { StarIcon } from "@heroicons/react/24/solid";
import { property } from "lodash";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";


const CommentListing = ({
  className = "",
  data,
  hasListingTitle,
}) => {

  const starRating = (index) => {
    return (
      <StarIcon className="w-4 h-4" key={index}/>
    )
  }

  const starRatingContent = (rating) => {
    let content = [];
    for (let i = 0; i < rating; i++) {
      content.push(
        starRating(i)
      );
    }
    return content;
  };


  return (
    <div
      className={`nc-CommentListing flex space-x-4 ${className}`}
      data-nc-id="CommentListing"
    >
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={data.user_name}
          imgUrl={data.avatar}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{data.user_name}</span>
              {hasListingTitle && (
                <>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {` review in `}
                  </span>
                  <a href="/">The Lounge & Bar</a>
                </>
              )}
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {data.comment_date}
            </span>
          </div>
          <div className="flex text-yellow-500">
            {starRatingContent(data.rating)}
          </div>
        </div>
        <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
          {data.comment}
        </span>
      </div>
    </div>
  );
};

export default CommentListing;
