import { useEffect } from "react";
import MyRouter from "routers/index.jsx";
import { pageViewAnalytics, setAnalytics } from "services/analyticsService";

function App() {

  useEffect(() => {
    pageViewAnalytics(window.location.pathname + window.location.search);
    setAnalytics(window.location.pathname);
  })

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
