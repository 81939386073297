import { Skeleton } from "@mui/material";
import React from "react";

const PropertyDetailSkeleton = () => {
  const PropertyDetailSkeletonMain = () => {

    const renderSection1 = () => {
      return (
        <div className="listingSection__wrap !space-y-6">
          <div className="flex justify-between items-center">
            <Skeleton variant="text" width={80} height={45} />
          </div>

          <div>
            <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          </div>

          <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
        </div>
      );
    };
    const renderPermitsSection = () => {
      return (
        <div className="listingSection__wrap">
          <div>
            <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <Skeleton variant="rectangular" />
          </div>
        </div>
      );
    };

    const renderSection2 = () => {
      return (
        <div className="listingSection__wrap">
          <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          <div className="text-neutral-6000 dark:text-neutral-300">
            <Skeleton variant="rectangular" />
          </div>
        </div>
      );
    };

    const renderSection3 = () => {
      return (
        <div className="listingSection__wrap">
          <div>
            <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <Skeleton variant="rectangular" />
          </div>

          {/* {renderMotalAmenities()} */}
        </div>
      );
    };

    const renderRoomRatesSection = () => {
      return (
        <div className="listingSection__wrap">
          <div>
            <div>
              <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
            </div>

            <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
              <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                <Skeleton variant="text" width={80} height={45} />
                <Skeleton variant="text" width={80} height={45} />
              </div>
              <div className="flex space-x-10 justify-between p-3">
                <Skeleton variant="text" width={80} height={45} />
                <Skeleton variant="text" width={80} height={45} />
              </div>
            </div>
          </div>

          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
          <div>
            <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flow-root">
            <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          </div>
        </div>
      );
    };

    const renderReviews = () => {
      return (
        <div className="listingSection__wrap">
          <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        </div>
      );
    };

    const renderSection7 = () => {
      return (
        <div className="listingSection__wrap">
          <div>
            <Skeleton variant="h2" sx={{ fontSize: "3rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

          {/* MAP */}
          <div className="aspect-h-5 sm:aspect-h-3 rounded-xl z-0">
            <div className="rounded-xl overflow-hidden z-0">
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          </div>
        </div>
      );
    };



    return (
      <div className="nc-PropertyDetailPage">
        <header className="rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer ">
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>

            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>

          <button>
          <Skeleton variant="text" width={80} height={45} />
          </button>
        </header>

        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            {renderSection1()}
            {renderPermitsSection()}
            {renderSection2()}
            {renderSection3()}
            {renderRoomRatesSection()}
            {renderReviews()}
            {renderSection7()}
          </div>
          
        </main>
      </div>
    );
  };

  return <>{PropertyDetailSkeletonMain()}</>;
};

export default PropertyDetailSkeleton;
