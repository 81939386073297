import useAPICall from "hooks/useAPICall";
import {LIST_BLOG_API_URL} from "constants/apis"

const useListBlog = () => {
  const [data, error, isLoading, callListBlog, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    // setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: 200,
      status_text: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData(res.data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_text: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_text: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback
    }
  ];

  const listBlog = (body) => {
    const url = LIST_BLOG_API_URL;
    callListBlog({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      body
    });
  };
  return [data, error, isLoading, listBlog, setSuccessData, setError];
};

export default useListBlog;
