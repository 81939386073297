import React, { FC } from "react";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { HOST_FORM_LINK } from "constants/property";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:w-full">
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Open Your Doors to CoziQ Hosting :)
        </h2>
        <video
          className="w-full mt-5 rounded-lg"
          // muted
          // autoPlay={true}
          preload="auto"
          // loop
          loop={true} muted={true} autoPlay={true} playsInline={true}
        >
          <source
            src="https://bmt-api-production.s3.ap-south-1.amazonaws.com/react-videos/hosting.mp4"
            type="video/mp4"
          />

        </video>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          All you need is a big space in your heart and small space at your home
          to be a Host on CoziQ. Start Hosting Guests from CoziQ to
          experience the joy of effortless hosting, Good Income and meeting New
          people. Let’s get you started today! <br></br>
        </span>
        <div className="text-center">
          <ButtonPrimary
            className="mt-3 sm:mt-10"
            href={HOST_FORM_LINK}
            targetBlank
          >
            Become a Host
          </ButtonPrimary>
        </div>
      </div>
      {/* <div className="flex-grow">
        <NcImage src={rightImg} />
      </div> */}
    </div>
  );
};

export default SectionBecomeAnAuthor;
