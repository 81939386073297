export const RESET_QUERY_PARAMS = {
    permits: "",
    city: "1", 
    area: "", 
    maximum_guests: "",
    space_categories: ""
  }

export const HOST_FORM_LINK = "https://forms.gle/Yswxfgq37jUunNsCA";
export const CONTACT_US_WHATSAPP_LINK = "https://wa.me/9677790546";
export const SPACE_CATEGORY_FILTER_NAME = "space_categories";
export const PERMIT_TYPE_FILTER_NAME = "permits";
export const CITY_FILTER_NAME = "city";
export const AREA_FILTER_NAME = "area";
export const MAX_GUESTS_FILTER_NAME = "maximum_guests";
