import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          
        </span>
        <h2 className="font-semibold text-4xl mt-5">Have you also ever felt? </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Budget" />
            <span className="block text-xl font-semibold">
            Private Venues are expensive?

            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Privatising restaurants/bars can be expensive, restricting the frequency 
            and size of your gatherings. Turning having dinner gatherings into a rare 
            and costly luxury.

            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Privacy " />
            <span className="block text-xl font-semibold">
            Lack of personal space when going out?

            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Dining out can often lack the homely atmosphere you desire. The noise 
            and the difficulty in conversing with everyone at your table can lead 
            to a less connected social experience.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Limitations" />
            <span className="block text-xl font-semibold">
            Not having enough home space?

            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Hosting dinners in smaller apartments or with roommates can be like
             fitting a square peg in a round hole, leading to compromises for group sizes.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
