"use client";

import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";

const LocationInput = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  suggestionsData = [],
  defaultValue,
  handleSearchDataChange,
  searchKeyName,
  searchSuggestionsEmptyMsg = "",
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);

  const setDefaultValues = () => {
    suggestionsData.map((item) => {
      if (item.id == defaultValue){
        setValue(item.name);
      }
    });
  }
  useEffect(() => {
    // Setting the default Values
    if (suggestionsData && defaultValue){
      setDefaultValues();
    }
    if (!defaultValue) {
      setValue("");
    }
  }, [defaultValue, suggestionsData])

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item) => {
    setValue(item.name || "");
    setShowPopover(false);
    handleSearchDataChange({ [searchKeyName]: item.id });
  };

  const handleClearInput = () => {
    setValue("");
    setShowPopover(false);
    // let emptyCity = {}
    // if (searchKeyName === "city") {
    //   emptyCity["area"] = "";
    // }
    handleSearchDataChange({ [searchKeyName]: "" });
  }

  const renderSearchSuggestions = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100"></h3>
        <div className="mt-2">
          {suggestionsData.length ? (
            suggestionsData.map((item) => (
              <span
                onClick={() => handleSelectLocation(item)}
                key={item.id}
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                </span>
                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                  {item.name}
                </span>
              </span>
            ))
          ) : (
            <span className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 dark:hover:bg-neutral-700 cursor-pointer">
              <span className="block font-small text-neutral-700 dark:text-neutral-200">
                {searchSuggestionsEmptyMsg}
              </span>
            </span>
          )}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    const filteredData = () => {
      return suggestionsData.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(value.toLowerCase())
      );
    };
    return (
      <>
        {filteredData().length > 0 ? (
          filteredData().map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.id}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))
        ) : (
          <span className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 dark:hover:bg-neutral-700 cursor-pointer">
            <span className="block font-small text-neutral-700 dark:text-neutral-200">
              No Record Found!
            </span>
          </span>
        )}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={handleClearInput}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : renderSearchSuggestions()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
