import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionPropertySearchPage from "components/SectionPropertySearchPage/SectionPropertySearchPage";
import React from "react";
import SectionGridFilterCard from "./SectionGridFilterCard.jsx";
import { Helmet } from "react-helmet";

const ListingPropertyPage = ({
  className = "",
  allPropertiesData,
  setAllPropertiesData,
  data,
  queryParams,
  setQueryParams,
}) => {

  return (
    <>
      <div
        className={`nc-ListingPropertyPage relative overflow-hidden ${className}`}
        data-nc-id="ListingPropertyPage"
      >
        <Helmet>
          <title>CoziQ Experiences</title>
        </Helmet>
        <BgGlassmorphism />

        <div className="container relative overflow-hidden">
          {/* SECTION HERO */}
          <SectionPropertySearchPage
            currentPage="Stays"
            currentTab="Stays"
            className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
            filtersData={data ? data : {}}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />

          {/* SECTION */}
          <SectionGridFilterCard
            className="pb-24 lg:pb-28"
            allPropertiesData={allPropertiesData}
            setAllPropertiesData={setAllPropertiesData}
            filtersData={data ? data : {}}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />

  
        </div>
      </div>
    </>
  );
};

export default ListingPropertyPage;
